import {
    addLayerWithRenderer,
    bindMapClickNavigation,
    bindMapFeatureEvents,
    bindMapFeatureTooltips,
    getFlatLayersList
} from '../../utils/arcgis';
import Color from '@arcgis/core/Color.js';
import Compass from '@arcgis/core/widgets/Compass.js';
import esriConfig from '@arcgis/core/config.js';
import Expand from '@arcgis/core/widgets/Expand.js';
import Extent from '@arcgis/core/geometry/Extent.js';
import FeatureFilter from '@arcgis/core/layers/support/FeatureFilter.js';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer.js';
import * as geometryEngine from '@arcgis/core/geometry/geometryEngine.js';
import Geometry from '@arcgis/core/geometry/Geometry.js';
import Graphic from '@arcgis/core/Graphic.js';
// import esriId from '@arcgis/core/identity/IdentityManager.js';
import Legend from '@arcgis/core/widgets/Legend.js';
import MapView from '@arcgis/core/views/MapView.js';
import Polygon from '@arcgis/core/geometry/Polygon.js';
import PortalItem from '@arcgis/core/portal/PortalItem.js';
import Print from '@arcgis/core/widgets/Print.js';
import * as projection from '@arcgis/core/geometry/projection.js';
import * as reactiveUtils from '@arcgis/core/core/reactiveUtils.js';
import SimpleRenderer from '@arcgis/core/renderers/SimpleRenderer.js';
import ScaleBar from '@arcgis/core/widgets/ScaleBar.js';
import SceneView from '@arcgis/core/views/SceneView.js';
import SimpleFillSymbol from '@arcgis/core/symbols/SimpleFillSymbol.js';
import WebMap from '@arcgis/core/WebMap.js';
import WebScene from '@arcgis/core/WebScene.js';

import { parseIndicatorAliases } from '../../widgets/AbstractWidget';
import { buildDisplayText } from '../../widgets/widgetHelpers';
import { appendAlternateViewButtons } from '../../widgets/widgetDataUtils';
import { TextWidget } from '../../widgets/TextWidget';
import { removeClass, addClass } from '../../utils/dom';
import { isNullOrUndefined, isNullOrWhitespace } from '../../utils/object';
import { getNumberFormatter } from '../../utils/localization';
import { ArcGISPortal } from 'data-catalog-js-api';

const QUERY = '(prefers-reduced-motion: reduce)';
export const prefersReducedMotion = window.matchMedia(QUERY).matches;

/**
 * Take a screenshot of the current map view.
 * @param mapView The map view to take a screenshot of.
 * @param mapScreenshotTarget If supplied, the image element to update the with generated screenshot.
 * @param mapScreenshotButton If supplied, the button element to update the with generated screenshot's data URL.
 */
export const takeScreenshot = async (
    mapView: MapView | null | undefined,
    mapScreenshotTarget?: HTMLImageElement | null,
    mapScreenshotButton?: HTMLAnchorElement | null,
    mapId?: string
): Promise<{
    dataUrl: string;
    data: ImageData;
} | null> => {
    if (
        mapView !== undefined &&
        mapView !== null &&
        mapView.ready &&
        (mapView.fatalError === undefined || mapView.fatalError === null)
    ) {
        try {
            const s: any = await mapView.takeScreenshot({
                format: 'png'
            });
            if (mapScreenshotTarget !== undefined && mapScreenshotTarget !== null) {
                mapScreenshotTarget.setAttribute('src', s.dataUrl);
                removeClass(mapScreenshotTarget, 'transparent');
            }
            if (mapScreenshotButton !== undefined && mapScreenshotButton !== null) {
                mapScreenshotButton.setAttribute('href', s.dataUrl);
            }
            return s;
        } catch (screenshotEx) {
            console.warn(`Error capturing screenshot for map #${mapId}: ${screenshotEx}`);
        }
    }
    return null;
};

export const findFeatureLayers = async (
    view: MapView | SceneView,
    layerIds: string | string[] | undefined = [],
    activeLayerUrl: string | undefined,
    activeLayerIndex: number | string = -1,
    activeFeature: any,
    autoAddActiveLayer = true,
    actOnImageLayers = true,
    skipRenderer = false,
    portalUrl?: string // Any auth should be handled by esriId
): Promise<{
    active?: any;
    selected: any[];
    rejected: any[];
}> => {
    const map = view.map,
        layersAreSpecified = layerIds !== undefined && layerIds !== null && layerIds.length > 0,
        viz = layersAreSpecified
            ? Array.isArray(layerIds)
                ? layerIds
                : layerIds.split(',')
            : activeLayerUrl !== undefined
            ? [activeLayerUrl]
            : [],
        matchAll = viz[0] === '*',
        selectedLayers: any[] = [],
        rejectedLayers: any[] = [],
        activeLayerIndexNum =
            activeLayerIndex !== undefined
                ? typeof activeLayerIndex === 'number'
                    ? activeLayerIndex
                    : parseInt(activeLayerIndex.toString())
                : -1,
        isActiveLayerMatch = (lyr, i) => {
            const urlMatch =
                    lyr.url !== undefined &&
                    lyr.url !== null &&
                    activeLayerUrl !== undefined &&
                    activeLayerUrl !== null &&
                    (lyr.url.toLowerCase() === activeLayerUrl.toLowerCase() ||
                        `${lyr.url}/${lyr.layerId}`.toLowerCase() === activeLayerUrl.toLowerCase()),
                indexMatch = activeLayerIndexNum >= 0 && i === activeLayerIndexNum;
            return activeLayerIndexNum >= 0 ? indexMatch : urlMatch;
        };
    let activePromise = Promise.resolve({
        selected: selectedLayers,
        rejected: rejectedLayers
    });
    let matchedActiveLayer: any = undefined;
    if (
        autoAddActiveLayer &&
        !isNullOrUndefined(map.layers) &&
        //!isNullOrUndefined(map.layers.items) &&
        getFlatLayersList(map.layers.toArray()).find((lyr, i) => isActiveLayerMatch(lyr, i)) === undefined &&
        activeLayerUrl !== undefined &&
        activeLayerUrl !== null &&
        activeLayerUrl.trim() !== ''
    ) {
        // If not already there, add... but check if it has a renderer too...
        const addLyr = new FeatureLayer({
            url: activeLayerUrl,
            visible: false // invisible by default, other things may turn it on...
        });
        map.layers.add(addLyr);
        // Should we set a renderer???
        if (!skipRenderer && portalUrl !== undefined && portalUrl !== null) {
            // Big try...catch because none of this should be fatal
            try {
                let lyrItemId: string | null | undefined = null,
                    lyrIndexId: number = -1;
                const isUtility =
                    /^https:\/\/utility.arcgis.com\/usrsvcs\/servers\/([0-9a-fA-F]+)\/rest\/services\/(.*)\/FeatureServer\/([0-9]+).*$/.exec(
                        activeLayerUrl
                    );
                if (isUtility !== null) {
                    lyrItemId = isUtility[1];
                    lyrIndexId = parseInt(isUtility[3]);
                } else {
                    await addLyr.load();
                    lyrItemId = addLyr.sourceJSON.serviceItemId;
                    lyrIndexId = addLyr.layerId;
                }
                if (lyrItemId !== null && lyrIndexId >= 0) {
                    const item = new PortalItem({
                            id: lyrItemId,
                            portal: {
                                url: portalUrl
                            }
                        }),
                        idata = await item.fetchData();
                    if (idata !== undefined && idata.layers !== undefined && Array.isArray(idata.layers)) {
                        const ilyr = idata.layers.find((ld) => ld.id === lyrIndexId);
                        if (
                            ilyr !== undefined &&
                            ilyr.layerDefinition !== undefined &&
                            ilyr.layerDefinition.drawingInfo !== undefined
                        ) {
                            const rjson = ilyr.layerDefinition.drawingInfo.renderer;
                            // Only allow simple renderers just now
                            if (
                                rjson !== undefined &&
                                rjson !== null &&
                                rjson.type !== undefined &&
                                rjson.type === 'simple'
                            ) {
                                const rr = SimpleRenderer.fromJSON(rjson);
                                addLyr.renderer = rr;
                            }
                        }
                    }
                }
            } catch (itemEx) {
                console.debug(itemEx); // DEBUG
            }
        }
        activePromise = Promise.resolve({
            selected: selectedLayers,
            rejected: rejectedLayers,
            active: addLyr
        });
    }
    if (viz.length > 0) {
        activePromise = activePromise.then(() => {
            const mapLayers = actOnImageLayers ? map.allLayers : map.layers,
                lowerLayers = viz.map((i) => i.toLowerCase().split('[')[0]),
                layerIndices = viz.map((i) => parseInt(i)); // Could be lots of NaN, but that's OK...
            let lyrName: string,
                lyrMatch: boolean,
                lyrUrl: string,
                lyrAt = 0,
                lyrIdx: number;
            for (let lyr of getFlatLayersList(mapLayers.toArray())) {
                if (lyr.type === 'feature') {
                    lyrUrl = !isNullOrUndefined(lyr.layerId)
                        ? `${lyr.url}/${lyr.layerId}`
                        : !isNullOrUndefined(lyr.url)
                        ? lyr.url
                        : 'xxxxx_impossible_id_xxxxx';
                    const lyrId = lyr.id;
                    lyrName = !isNullOrUndefined(lyr.title)
                        ? lyr.title
                        : !isNullOrUndefined(lyr.name)
                        ? lyr.name
                        : '<>';
                    lyrIdx = Math.max(
                        lowerLayers.indexOf(lyrUrl.toLowerCase()),
                        lowerLayers.indexOf(lyrId.toLowerCase()),
                        lowerLayers.indexOf(lyrName.toLowerCase()),
                        lowerLayers
                            .filter((v) => v.indexOf('*') > 0)
                            .findIndex((v) => lyrId.toLowerCase().indexOf(v.substring(0, v.indexOf('*'))) === 0)
                    );
                    lyrMatch = lyrIdx >= 0 || layerIndices.indexOf(lyrAt) >= 0;
                    if (lyrIdx >= 0 && viz[lyrIdx].indexOf('[') > 0 && !isNullOrUndefined(activeFeature)) {
                        // Filter...
                        const fld = viz[lyrIdx].split('[')[1].replace(']', '');
                        lyr.definitionExpression = (Array.isArray(activeFeature) ? activeFeature : [activeFeature])
                            .map((f) => `${fld} = '${f.id}'`)
                            .join(' OR ');
                    }
                    if (isActiveLayerMatch(lyr, lyrAt)) {
                        matchedActiveLayer = lyr;
                    }
                    if (lyrMatch || matchAll) {
                        selectedLayers.push(lyr);
                    } else {
                        rejectedLayers.push(lyr);
                    }
                    lyrAt++;
                }
                // Non-feature layers - are we adjusting them too?
                else if (actOnImageLayers && layersAreSpecified) {
                    const lyrId = lyr.id;
                    lyrName = !isNullOrUndefined(lyr.title)
                        ? lyr.title
                        : !isNullOrUndefined(lyr.name)
                        ? lyr.name
                        : '<>';
                    lyrIdx = Math.max(
                        lowerLayers.indexOf(lyrId.toLowerCase()),
                        lowerLayers.indexOf(lyrName.toLowerCase()),
                        lowerLayers
                            .filter((v) => v.indexOf('*') > 0)
                            .findIndex((v) => lyrId.toLowerCase().indexOf(v.substring(0, v.indexOf('*'))) === 0)
                    );
                    lyrMatch = lyrIdx >= 0 || layerIndices.indexOf(lyrAt) >= 0;
                    if (lyrMatch || matchAll) selectedLayers.push(lyr);
                    else rejectedLayers.push(lyr);
                    lyrAt++;
                }
            }
            return {
                active: matchedActiveLayer,
                selected: selectedLayers,
                rejected: rejectedLayers
            };
        });
    }
    return activePromise;
};

export const checkLayerLists = (list1: string | string[] = [], list2: string | string[] = []) => {
    const lyrs1 = Array.isArray(list1) ? list1 : list1.toString().split(','),
        lyrs2 = Array.isArray(list2) ? list2 : list2.toString().split(',');
    lyrs1.sort();
    lyrs2.sort();
    return lyrs1.join('\t').toLowerCase() === lyrs2.join('\t').toLowerCase();
};

export const rebuildMap = async (
    mapNode: HTMLDivElement,
    legendNode: HTMLDivElement | null,
    props: { [key: string]: any }
): Promise<any> => {
    // Fetch objects from properties - note that "map" is a JSON version of the map definition - this makes it easier
    // for PureComponent to do a shallow compare of properties and to trigger (or not) an update/render()
    const {
            user,
            portalUrl,
            portalType,
            token,
            id,
            basemap,
            mapId,
            mapExtent,
            mapBackground = '#fff',
            mapCallbackScript,
            onMapLoad,
            onMapError,
            webMapId = '',
            activeFeature,
            activeLayer,
            activeLayerIndex = -1,
            legend = false,
            legendAnchor = 'bottom-right',
            legendStyle = '',
            legendSize = 'small', // small|standard|large
            northArrow = 'none',
            northArrowAnchor = 'top-left',
            use3d = false,
            backgroundStars3d = false,
            atmosphere3d = false,
            //viewingMode3d = 'global',
            thematicApplyToLayer = false,
            showMapPopups = 'none',
            visibleLayers,
            visibleImageLayers = 'specific', // 'specific' = use visible layers, '*' = do not filter
            scalebar = 'none', // "ruler"|"line"
            scalebarAnchor = 'bottom-left',
            scalebarUnits = 'metric', // "non-metric"|"metric"|"dual"
            showZoomSlider = true,
            showExportLink = false,
            showPrintButton = false,
            showMapWhen = 'in-viewport', // 'in-viewport' | 'always'
            inViewport = true, // presume it is in viewport unless told otherwise
            disableMapAnimation = false,
            forceWaitForLayersLoad = false,
            zoomToActiveFeature = false
        } = props,
        updateRequired = inViewport || showMapWhen === 'always',
        options = portalType.toLowerCase() === 'online' ? { version: '4.22' } : { version: '4.22' },
        activeWebMapId =
            !isNullOrWhitespace(webMapId) && webMapId !== '[auto]' && webMapId !== 'auto' ? webMapId : mapId; // Allowing user override
    if (!isNullOrUndefined(mapNode)) addClass(mapNode, 'placeholder');
    /*if (map !== undefined && map !== null) {
        //const elementId = this._map.id;
        map.destroy();
        // Again - ArcGIS and destroy() suck in React world - so ditch it manually
        //if (!isNullOrUndefined(this.mapNode.firstElementChild)) this.mapNode.removeChild(this.mapNode.firstElementChild);
    }
    if (this._legendView !== undefined && this._legendView !== null) this._legendView.destroy();*/
    /*if (
        !isNullOrUndefined(this._itemDeferred) &&
        !this._itemDeferred.isResolved() &&
        !this._itemDeferred.isFulfilled()
    )
        this._itemDeferred.cancel('abort:update', false);
    if (
        !isNullOrUndefined(this._mapDeferred) &&
        !this._mapDeferred.isResolved() &&
        !this._mapDeferred.isFulfilled()
    )
        this._mapDeferred.cancel('abort:update', false);
        */
    if (updateRequired) {
        try {
            // Portal?
            if (portalType.toLowerCase() !== 'online') {
                // Set the hostname to the on-premise portal
                esriConfig.portalUrl = portalUrl.replace(/\/sharing\/rest(\/)?$/, '');
            }
            // Create map with the given options at a DOM node w/ id 'mapNode'
            //const webMapOptions = {};
            //if ((mapExtent !== undefined) && (mapExtent !== null)) webMapOptions.extent = webMercatorUtils.webMercatorToGeographic(new Extent(mapExtent)).toJson();
            //let agoWebMap = null; //(!isNullOrUndefined(map) ? JSON.parse(map) : null);
            //if (isNullOrUndefined(agoWebMap) || isNullOrUndefined(agoWebMap.itemData)) agoWebMap = ArcGISUtils.getVanillaWebMap(webMapOptions);
            let geoView: MapView | SceneView | null = null,
                legendView: any = null;

            const mapArgs: any =
                activeWebMapId !== undefined && activeWebMapId !== null
                    ? {
                          portalItem: {
                              id: activeWebMapId
                          }
                      }
                    : {
                          basemap: basemap || 'gray-vector',
                          layers: [
                              new FeatureLayer({
                                  url: activeLayer
                              })
                          ]
                      };
            if (use3d) {
                mapArgs.ground = 'world-elevation';
            }
            const isSpecificWebScene =
                    use3d && activeWebMapId !== undefined && activeWebMapId !== null
                        ? (
                              await ArcGISPortal.getInfo(
                                  `${portalUrl.replace(/^\/(.*)\/$/, '$1')}/content/items/${activeWebMapId}`,
                                  { f: 'json', token: token }
                              )
                          ).type === 'Web Scene'
                        : false,
                map = isSpecificWebScene ? new WebScene(mapArgs) : new WebMap(mapArgs);
            await map.load(); // Should trigger an error for invalid maps
            geoView = use3d
                ? new SceneView({
                      map: map,
                      container: mapNode,
                      environment: {
                          background: {
                              type: 'color',
                              color: mapBackground
                          },
                          starsEnabled: backgroundStars3d,
                          atmosphereEnabled: atmosphere3d
                      }
                  })
                : new MapView({
                      map: map,
                      container: mapNode
                  });
            //mapView.watch('fatalError', (error) => {
            //    if (error) {
            //        window.setTimeout(mapView.tryFatalErrorRecovery, 1000);
            //    }
            //});
            if (!showZoomSlider) geoView.ui.remove('zoom');
            if (showPrintButton && !showExportLink && (geoView as MapView) !== undefined) {
                const pb = new Print({
                        view: geoView as MapView,
                        portal: {
                            url: portalUrl
                        }
                    }),
                    pbe = new Expand({
                        view: geoView,
                        content: pb
                    });
                geoView.ui.add(pbe, 'top-left');
            }
            if (legend !== false && legend !== '0' && legend !== 'none' && !thematicApplyToLayer) {
                // If legend is for thematics, wait for them before adding
                const existingSidebarLegend = document.getElementById(`mapLegend${id}`),
                    legendSidebar = legendAnchor.indexOf('sidebar') === 0;
                if (legendSidebar && legendNode !== null && legendNode.childNodes.length < 1)
                    legendNode.appendChild(legendNode.ownerDocument.createElement('div'));
                const legendArgs = {
                    view: geoView,
                    container:
                        legendSidebar &&
                        legendNode?.firstElementChild !== undefined &&
                        legendNode?.firstElementChild !== null
                            ? (legendNode?.firstElementChild as HTMLElement)
                            : undefined
                };
                // Sidebar - changes the layout significantly...? And may be in a dojo tangle, sigh...
                // if (legendSidebar && existingSidebarLegend === null) {
                //     const nleg = document.createElement('div'),
                //         sidebar = mapNode.parentNode.querySelector(
                //             '.ia-arc-legend.ia-legend-sidebar'
                //         );
                //     if (sidebar !== undefined && sidebar !== null) {
                //         nleg.setAttribute('id', `mapLegend${id}`);
                //         sidebar.appendChild(nleg);
                //     }
                // }
                legendView = new Legend(legendArgs);
                if (!legendSidebar) geoView.ui.add(legendView, legendAnchor);
                if (legendSize !== null && legendView.container !== null)
                    addClass(legendView.container, `ia-arc-legend ia-legend-${legendSize}`);
                if (legendStyle !== '' && legendView.container !== null)
                    legendView.container.setAttribute('style', legendStyle);
            }
            if (
                !isNullOrUndefined(scalebar) &&
                scalebar !== false &&
                scalebar.toLowerCase() !== 'none' &&
                geoView.type === '2d'
            ) {
                const scaleBar = new ScaleBar({
                    view: geoView as MapView,
                    style: scalebar,
                    unit: scalebarUnits.toLowerCase()
                });
                // Add widget to the bottom left corner of the view
                geoView.ui.add(scaleBar, {
                    position: scalebarAnchor
                });
            }
            if (!isNullOrUndefined(northArrow) && northArrow !== false && northArrow.toLowerCase() !== 'none') {
                const compass = new Compass({
                    view: geoView
                });
                geoView.ui.add(compass, northArrowAnchor);
                // This is rubbish - should be part of the code!
                // addClass(
                //     compass.domNode,
                //     `ia-map-compass ia-north-arrow-${northArrow.toLowerCase()}`
                // );
            }
            await geoView.when(
                () => {},
                (promiseErr) => {
                    console.debug(promiseErr); // DEBUG
                    if (!isNullOrUndefined(onMapError)) onMapError(promiseErr);
                }
            );
            const layersAreSpecified =
                    !isNullOrUndefined(visibleLayers) && visibleLayers !== '' && visibleLayers.length > 0,
                viz = layersAreSpecified
                    ? Array.isArray(visibleLayers)
                        ? visibleLayers
                        : visibleLayers.split(',')
                    : [],
                vizLayers = await findFeatureLayers(
                    geoView,
                    viz,
                    activeLayer,
                    activeLayerIndex,
                    activeFeature,
                    true,
                    visibleImageLayers !== '*',
                    false,
                    portalUrl
                );
            // Explicitly chosen
            if (layersAreSpecified) {
                for (let lyr of vizLayers.rejected) {
                    lyr.visible = false;
                    if (lyr.type === 'feature' && typeof lyr.popupEnabled !== 'undefined')
                        lyr.popupEnabled = lyr.visible && showMapPopups.toString().toLowerCase() === 'all'; //this._mapDeferred = arcgisUtils.createMap(JSON.parse(JSON.stringify(agoWebMap)), this.mapNode, {
                }
                for (let lyr of vizLayers.selected) {
                    lyr.visible = true;
                    if (lyr.type === 'feature' && typeof lyr.popupEnabled !== 'undefined')
                        lyr.popupEnabled = lyr.visible && showMapPopups.toString().toLowerCase() === 'all'; //this._mapDeferred = arcgisUtils.createMap(JSON.parse(JSON.stringify(agoWebMap)), this.mapNode, {
                }
            }
            // Active layer? Make it visible if there is nothing else visible
            else if (vizLayers.active !== undefined) {
                let allHidden = true;
                for (const lyr of getFlatLayersList(geoView.map.layers.toArray())) {
                    allHidden = allHidden && !lyr.visible;
                }
                if (allHidden) {
                    vizLayers.active.visible = true;
                    // Grouped?
                    if (vizLayers.active.parent !== undefined && vizLayers.active.parent !== null) {
                        vizLayers.active.parent.visible = true;
                    }
                    if (vizLayers.active.type === 'feature' && typeof vizLayers.active.popupEnabled !== 'undefined') {
                        vizLayers.active.popupEnabled =
                            vizLayers.active.visible && showMapPopups.toString().toLowerCase() === 'all';
                    }
                }
            }
            let loadLayersPromises: Promise<any>[] = [];
            if (forceWaitForLayersLoad && geoView !== null) {
                geoView.map.allLayers.forEach((lyr) => {
                    if (geoView !== null) {
                        loadLayersPromises.push(
                            geoView.whenLayerView(lyr).then((lv: any) => {
                                return reactiveUtils.whenOnce(() => lv.updating === false);
                            })
                        );
                    }
                });
            }
            //    mapOptions: {
            //        slider: true
            //    }
            //});
            //this._mapDeferred.then((response) =>
            //{
            let mx: Extent | null = null,
                mxLyr;
            const portalItem: any =
                geoView.map instanceof WebMap && !isNullOrUndefined(geoView.map.portalItem)
                    ? geoView.map.portalItem
                    : null;
            if (portalItem !== null && !isNullOrUndefined(portalItem.extent)) {
                mx = !isNullOrUndefined(portalItem.extent.xmin)
                    ? portalItem.extent
                    : new Extent({
                          xmin: portalItem.extent[0][0],
                          ymin: portalItem.extent[0][1],
                          xmax: portalItem.extent[1][0],
                          ymax: portalItem.extent[1][1]
                      });
            } else if (
                (mxLyr = getFlatLayersList(geoView.map.layers.toArray()).find(
                    (lyr) => `${lyr.url}/${lyr.layerId}` === activeLayer
                )) !== undefined &&
                geoView !== null
            ) {
                mx = mxLyr.fullExtent;
                await mxLyr.when(() => {
                    if (geoView !== null) {
                        // silly uber-strict typescript
                        geoView.goTo(mxLyr.fullExtent, {
                            animate: !prefersReducedMotion && !disableMapAnimation && !zoomToActiveFeature
                        });
                    }
                });
                mx = null; // Reset because we've done it once already
            }
            if (mapExtent !== undefined && mapExtent !== null) {
                const [xmin, ymin, xmax, ymax] = mapExtent.toString().split(/[,\s]/);
                mx = new Extent({
                    xmin: parseFloat(xmin),
                    ymin: parseFloat(ymin),
                    xmax: parseFloat(xmax),
                    ymax: parseFloat(ymax)
                });
            }
            //console.log(mx); // DEBUG
            if (mx !== null) {
                await geoView
                    .goTo(mx, {
                        animate: !prefersReducedMotion && !disableMapAnimation && !zoomToActiveFeature
                    })
                    .catch((error) => {
                        if (error.name !== 'AbortError') {
                            console.debug(error);
                        }
                    });
            }
            // Pick up any other settings (Report Builder widget-y ones) and act on them
            const adjustments: { [key: string]: any } = await applyWidgetSettings(
                props,
                geoView.container.closest('.ia-report-widget'),
                geoView,
                activeLayer,
                legendNode
            );
            if (adjustments.legend !== undefined) legendView = adjustments.legend;
            // Any callback?
            if (!isNullOrUndefined(onMapLoad)) {
                onMapLoad({
                    type: 'load',
                    view: geoView,
                    item: portalItem
                });
            }
            if (!isNullOrUndefined(mapCallbackScript)) {
                const mcf = typeof mapCallbackScript === 'function' ? mapCallbackScript : window[mapCallbackScript];
                try {
                    mcf({
                        type: 'load',
                        view: geoView,
                        map: geoView.map,
                        item: portalItem,
                        active: {
                            layer: activeLayer,
                            feature: activeFeature
                        }
                    });
                } catch (mcfErr) {
                    console.debug(`Cannot execute map callback: ${mcfErr}`);
                }
            }
            //this._map = this._mapView.map;
            //}, (promiseErr) =>
            //{
            //    console.log(promiseErr); // DEBUG
            //});
            await Promise.allSettled(loadLayersPromises);
            return {
                view: geoView,
                legend: legendView,
                error: adjustments['error'] // Internal but non-fatal(?) errors
            };
        } catch (err) {
            // Handle any script or module loading errors
            if (!isNullOrUndefined(onMapError)) onMapError(err);
        }
    }
    return {};
};

/** Apply RB widget settings to the map - these go beyond the basic map setup to add interaction etc.
 */
export const applyWidgetSettings = async (
    props: { [key: string]: any },
    container: any,
    geoView: MapView | SceneView,
    primaryLayerUrl: string | null = null,
    legendNode: HTMLDivElement | null
): Promise<{ [key: string]: any }> => {
    const {
            id,
            activeFeature,
            activeLayerIndex = -1,
            zoomToActiveFeature = false,
            zoomToActiveFeatureMargin = '0',
            zoomToActivePointExtent = '1000',
            zoomToScale = -1,
            highlightSelectedFeature = false,
            highlightColor = '#ffffd7',
            //highlightOutlineWidth = '4',
            //highlightPointMarker = 'Auto',
            //highlightPointMarkerSize = '10',
            highlightType = 'Outline',
            visibleLayers,
            visibleLayersFilter,
            clipVisibleLayers = false,
            clipVisibleLayersBuffer = 0,
            clipVisibleLayersStyle = 'clip', // 'clip' or 'fade' etc.
            clipHighlightLayersStyle = 'none',
            clipVisibleLayersType = 'contains', // spatial relationship - contains, intersects etc.
            drawClipBoundary = false,
            drawClipBoundaryColor = '#4A3875',
            generalizeClipBoundary = 10,
            includeAllAreas = true,
            indicatorAliases = null,
            locale = 'en',
            navigateOnFeatureClick = false,
            navigationLinkFormat = './#ID',
            numberFormat = '0.#',
            globals = {},
            showMapPopups = 'none',
            showMapToolTips = true,
            mapToolTipFormat = '#FNAME',
            text = null,
            titleText = null,
            thematicApplyToLayer = false,
            thematicClassification = 'natural-breaks',
            thematicScheme = '',
            thematicSchemeType = 'high-to-low',
            thematicSchemeFlip = false,
            legend = false,
            legendAnchor = 'bottom-left',
            legendStyle = '',
            legendSize = 'standard',
            thematicLegendLabel = '#INAME{1} | #IDATE{1}',
            thematicNumberOfClasses = '4',
            thematicRenderType = 'color',
            indicators = [],
            dataProvider,
            history: routerHistory,
            disableMapAnimation = false,
            eventsGenerate = 'none',
            eventsListen = 'none'
        } = props,
        dataOptions = !isNullOrUndefined(activeFeature)
            ? {
                  activeFeature: Array.isArray(activeFeature)
                      ? [...activeFeature]
                      : {
                            ...activeFeature
                        }
              }
            : null,
        startPromise =
            dataProvider !== undefined && dataProvider !== null && indicators.length > 0
                ? dataProvider.getData(includeAllAreas ? null : activeFeature.id, indicators, dataOptions)
                : Promise.resolve(null),
        datasets = await startPromise,
        outputs: { [key: string]: any } = {};
    //console.log(`ArcWebMap pre-data-timestamp=${new Date().toISOString()}`); // DEBUG
    //console.log(`ArcWebMap post-data-timestamp=${new Date().toISOString()}`); // DEBUG
    //console.log('++++++++++++++++++++++++++');
    //console.log(projection);

    const tt = titleText !== null ? titleText : text;
    if (container !== null && tt !== null && datasets !== null && tt.indexOf('#') >= 0) {
        let txt = tt;
        txt = TextWidget.insertValuesIntoText(txt, datasets, numberFormat, locale, '');
        if (!isNullOrUndefined(activeFeature)) {
            const fArray = Array.isArray(activeFeature) ? activeFeature : [activeFeature];
            txt = buildDisplayText(txt, fArray, globals, locale).text;
        }
        const titleNode = container.querySelector('.ia-text-box-title');
        if (titleNode !== null) {
            // async so could have gone away...
            if (txt.indexOf('</') > 0) titleNode.innerHTML = txt;
            else {
                titleNode.removeChild(titleNode.firstChild);
                titleNode.appendChild(container.ownerDocument.createTextNode(txt));
            }
        }
    }
    let activeLayerPromise: Promise<any> = Promise.resolve(null),
        dataField: string | null = null,
        tipFunction: undefined | ((a: any) => any) = undefined,
        dataTipFormat: string | undefined = undefined;
    // Thematics? Active layer is therefore ignored and a different layer is injected...
    if (thematicApplyToLayer && datasets !== null && datasets.length > 0) {
        const ds = datasets.find((d) => d.indicators !== undefined && d.indicators !== null && d.indicators.length > 0);
        if (ds !== undefined) {
            dataField = ds.colIds[1].field;
            const ind = ds.indicators.find((i) => i.id === ds.colIds[1].iid),
                isTable = ind.srcType === 'Table',
                aliases = indicatorAliases != null ? parseIndicatorAliases(indicatorAliases) : [];
            let outf =
                !isNullOrUndefined(globals) && !isNullOrUndefined(globals.nameField)
                    ? [globals.idField, globals.nameField, dataField]
                    : [dataField];
            let label = TextWidget.insertValuesIntoText(thematicLegendLabel, [ds], numberFormat, locale, '');
            let fa;
            if ((fa = aliases.find((a) => a.id === ind.id)) !== undefined) {
                ind.name = fa.label;
                label = TextWidget.insertValuesIntoText(thematicLegendLabel, [ds], numberFormat, locale, '');
            }
            let geoSrcUrl = ind.url || ind.src;
            const classif: any = {
                method: thematicClassification.replace(/([a-z0-9])([A-Z])/g, '$1-$2').toLowerCase(), // Deal with legacy strings
                classes: parseInt(thematicNumberOfClasses),
                scheme: thematicScheme, // This is new, not using the default classifier, only the smart rendering version...
                theme: thematicSchemeType,
                flip: thematicSchemeFlip,
                field: {
                    name: dataField,
                    label: label
                },
                type: thematicRenderType
            };
            dataTipFormat = (
                mapToolTipFormat !== '' ? mapToolTipFormat : `$feature.${globals.nameField}: $feature.${dataField}`
            )
                .replace(/\{NAME\}/g, `$feature.${globals.nameField}`)
                .replace(/(#F?ID|\{ID\})/g, `$feature.${globals.idField}`)
                .replace(/(#F?NAME|\{NAME\})/g, `$feature.${globals.nameField}`)
                .replace(/(#F?VALUE(\{1\}|\{active\})?|\{VALUE\})/g, `$feature.${dataField}`)
                .replace(/\{([0-9a-zA-Z_]+)\}/g, '$feature.$1'); // Legacy - older style ArcGIS format, translated...

            // Locally derived data will override the field classification
            if (isTable) {
                classif.data = ds;
                geoSrcUrl = datasets.find((d) => d.primary === true).geo.src;
                outf = ['*'];
                const nf = getNumberFormatter(locale, numberFormat);
                tipFunction = (atts) => {
                    const key = atts[globals.idField];
                    let fname = '';
                    const labels = (dataTipFormat || '')
                            .split('$feature.')
                            .filter((lbl) => lbl !== undefined && lbl !== ''),
                        keys = labels.map((lbl) => lbl.split(/[^0-9a-zA-Z_]/)[0]);
                    if (!isNullOrUndefined(key)) {
                        const rowIdx = ds.rowIds.findIndex((ri) => ri === key);
                        if (rowIdx >= 0) {
                            for (let i = 0; i < labels.length; i++) {
                                let dv: any = keys[i] === dataField ? ds.rows[rowIdx][1] : atts[keys[i]];
                                if (typeof dv === 'number') dv = nf.format(dv);
                                fname += labels[i].replace(keys[i], dv);
                            }
                            return fname;
                        }
                    }
                    // Fallback...
                    for (let i = 0; i < labels.length; i++) {
                        let dv: any = atts[keys[i]];
                        if (typeof dv === 'number') dv = nf.format(dv);
                        fname += labels[i].replace(keys[i], dv);
                    }
                    return fname;
                };
            }
            if (!isNullOrUndefined(container))
                appendAlternateViewButtons(
                    container,
                    container.querySelectorAll('.ia-chart-data-table-link'),
                    { ...props },
                    datasets
                );
            const legendSidebar = legendAnchor.indexOf('sidebar') === 0;
            if (legendSidebar && legendNode !== null && legendNode.childNodes.length < 1)
                legendNode.appendChild(legendNode.ownerDocument.createElement('div'));
            activeLayerPromise = addLayerWithRenderer(
                geoView,
                {
                    url: geoSrcUrl,
                    //title: ' ',
                    outFields: outf,
                    classification: classif,
                    idField: globals.idField,
                    nameField: globals.nameField,
                    flip: false,
                    name: null
                },
                legend
                    ? {
                          container: legendSidebar ? legendNode?.firstElementChild : '',
                          anchor: legendAnchor,
                          size: legendSize,
                          style: legendStyle,
                          show: true
                      }
                    : {
                          show: false,
                          container: '',
                          anchor: '',
                          size: '',
                          style: ''
                      },
                true
            );
        }
    } else if (!isNullOrUndefined(activeLayerIndex) && parseInt(activeLayerIndex) >= 0) {
        activeLayerPromise = Promise.resolve({
            layer: geoView.map.layers.getItemAt(parseInt(activeLayerIndex))
        });
    } else if (primaryLayerUrl !== null) {
        activeLayerPromise = Promise.resolve({
            layer: getFlatLayersList(geoView.map.layers.toArray()).find(
                (lyr: any) => `${lyr.url}/${lyr.layerId}`.toLowerCase() === primaryLayerUrl.toLowerCase()
            )
        });
    }
    try {
        const layerPromiseResult: any = await activeLayerPromise,
            activeLayer: any =
                layerPromiseResult !== undefined && layerPromiseResult !== null ? layerPromiseResult.layer : undefined;
        if (
            layerPromiseResult !== undefined &&
            layerPromiseResult !== null &&
            layerPromiseResult.legend !== undefined &&
            layerPromiseResult.legend !== null
        )
            outputs['legend'] = layerPromiseResult.legend;
        if (activeLayer !== undefined && activeLayer !== null) {
            activeLayer.popupEnabled = showMapPopups.toString().toLowerCase() !== 'none';
            if (
                !includeAllAreas &&
                !isNullOrUndefined(activeFeature) &&
                !isNullOrUndefined(globals) &&
                !isNullOrUndefined(globals.idField)
            ) {
                activeLayer.definitionExpression = (Array.isArray(activeFeature) ? activeFeature : [activeFeature])
                    .map((f) => `${globals.idField} = '${f.id}'`)
                    .join(' OR ');
            }
            const activeLayerView = await geoView.whenLayerView(activeLayer);
            const navigable =
                navigateOnFeatureClick && !isNullOrUndefined(globals) && !isNullOrUndefined(globals.idField);
            if (eventsGenerate !== 'none' && !isNullOrUndefined(globals) && !isNullOrUndefined(globals.idField)) {
                bindMapFeatureEvents(
                    eventsGenerate.replace('hover', 'pointer-move'),
                    geoView,
                    activeLayer,
                    globals.idField,
                    globals.nameField
                );
            }
            if (showMapToolTips && !isNullOrUndefined(globals) && !isNullOrUndefined(globals.nameField)) {
                if (dataTipFormat === undefined) {
                    dataTipFormat = (mapToolTipFormat !== '' ? mapToolTipFormat : `$feature.${globals.nameField}`)
                        .replace(/\{NAME\}/g, `$feature.${globals.nameField}`)
                        .replace(/(#F?ID|\{ID\})/g, `$feature.${globals.idField}`)
                        .replace(/(#F?NAME|\{NAME\})/g, `$feature.${globals.nameField}`)
                        .replace(/(#F?VALUE(\{1\}|\{active\})?|\{VALUE\})/g, `$feature.${dataField}`)
                        .replace(/\{([0-9a-zA-Z_]+)\}/g, '$feature.$1'); // Legacy - older style ArcGIS format, translated...
                }
                bindMapFeatureTooltips(
                    geoView,
                    activeLayer,
                    dataTipFormat,
                    numberFormat,
                    locale,
                    tipFunction,
                    navigable ? 'pointer' : 'no'
                );
            }
            if (navigable) {
                const standardRootPath = globals.baseHref;
                let url: string | undefined = undefined;
                if (standardRootPath !== undefined && navigationLinkFormat.indexOf('.') === 0) {
                    url = new URL(navigationLinkFormat, standardRootPath)
                        .toString()
                        .replace('/view-report/view-report/', '/view-report/'); // Common mistake so may as well deal with it...
                }
                bindMapClickNavigation(
                    geoView,
                    activeLayer,
                    globals.idField,
                    globals.nameField,
                    url !== undefined && isNullOrUndefined(routerHistory) ? url : navigationLinkFormat,
                    routerHistory
                );
            }
            // Visible layers (and non-spatial filters)
            const viz =
                !isNullOrUndefined(visibleLayers) && visibleLayers !== ''
                    ? Array.isArray(visibleLayers)
                        ? visibleLayers
                        : visibleLayers.split(',')
                    : [];
            const visibleLayersPromise =
                clipVisibleLayers ||
                (visibleLayersFilter !== undefined && visibleLayersFilter !== null && visibleLayersFilter !== '')
                    ? findFeatureLayers(
                          geoView,
                          viz,
                          undefined,
                          undefined,
                          undefined,
                          false,
                          false,
                          false,
                          globals.portalUrl
                      )
                    : Promise.resolve({
                          selected: [],
                          rejected: []
                      });
            const layerFilterLookup = new Map<string, string>();
            if (visibleLayersFilter !== undefined && visibleLayersFilter !== null && visibleLayersFilter !== '') {
                const tokens = visibleLayersFilter.split(',');
                for (const t of tokens) {
                    const [layerKey, filterValue] = t.split(':');
                    let whereText = filterValue;
                    if (activeFeature !== undefined) {
                        whereText = whereText.replace(
                            new RegExp(`[$]feature[.]${globals.idField}`, 'gi'),
                            activeFeature.map((f) => f.id).join(',')
                        );
                        whereText = whereText.replace(
                            new RegExp(`[$]feature[.]${globals.nameField}`, 'gi'),
                            activeFeature.map((f) => f.name).join(',')
                        );
                    }
                    whereText = TextWidget.insertValuesIntoText(whereText, datasets, numberFormat, locale, '', false);
                    layerFilterLookup.set(layerKey, whereText);
                }
                // No spatial filter - so apply attributes now, otherwise we can wait
                const filterableLayers = await visibleLayersPromise;
                for (const lyr of filterableLayers.selected) {
                    const lyrWhere = layerFilterLookup.has('*')
                        ? layerFilterLookup.get('*')
                        : layerFilterLookup.has(lyr.id)
                        ? layerFilterLookup.get(lyr.id)
                        : layerFilterLookup.has(lyr.title)
                        ? layerFilterLookup.get(lyr.title)
                        : undefined;
                    if (lyrWhere !== undefined) {
                        geoView.whenLayerView(lyr).then((layerView) => {
                            reactiveUtils
                                .whenOnce(() => layerView.updating === false)
                                .then(() => {
                                    const ff = new FeatureFilter({
                                        where: lyrWhere
                                    });
                                    if (
                                        clipVisibleLayersStyle.indexOf('fade') >= 0 &&
                                        layerView.layer.type === 'feature'
                                    ) {
                                        (layerView as any).featureEffect = getFeatureEffect(
                                            ff,
                                            clipVisibleLayersStyle,
                                            clipHighlightLayersStyle,
                                            drawClipBoundaryColor
                                        );
                                        //console.log(layerView.featureEffect); // DEBUG
                                    } else {
                                        (layerView as any).filter = ff;
                                        //console.log(layerView.filter); // DEBUG
                                    }
                                });
                        });
                    }
                }
            }
            // Zoom? Highlight? Spatial filter?
            if (
                !isNullOrUndefined(activeFeature) &&
                (zoomToActiveFeature ||
                    highlightSelectedFeature ||
                    clipVisibleLayers ||
                    eventsListen.toLowerCase() !== 'none') &&
                !isNullOrUndefined(globals) &&
                !isNullOrUndefined(globals.idField)
            ) {
                const featureSet = Array.isArray(activeFeature) ? activeFeature : [activeFeature],
                    numericId = /^[^0a-zA-Z_][0-9]*$/.test(featureSet[0].id),
                    idfType =
                        activeLayerView.layer.type === 'feature' &&
                        (activeLayerView.layer as FeatureLayer).fields !== undefined &&
                        (activeLayerView.layer as FeatureLayer).fields.find(
                            (idf) => idf.name.toLowerCase() === globals.idField.toLowerCase()
                        ) !== undefined
                            ? (activeLayerView.layer as FeatureLayer).fields.find(
                                  (idf) => idf.name.toLowerCase() === globals.idField.toLowerCase()
                              )?.type
                            : undefined,
                    isNumericIdf =
                        idfType !== undefined &&
                        ['small-integer', 'single', 'long', 'integer', 'double', 'oid', 'big-integer'].indexOf(
                            idfType
                        ) >= 0,
                    whereClause = isNumericIdf
                        ? `${globals.idField} IN (${featureSet.map((f) => f.id).join(',')})`
                        : `${globals.idField} IN (${featureSet.map((f) => `'${f.id}'`).join(',')})`,
                    fq = activeLayer.createQuery();
                fq.where = whereClause;
                if (zoomToActiveFeature || clipVisibleLayers) {
                    const clippers: any = await visibleLayersPromise;
                    const results: any = await activeLayer.queryFeatures(fq);
                    const nMatches = results.features.length;
                    const xp =
                        activeLayer.geometryType === 'point'
                            ? parseFloat(zoomToActivePointExtent)
                            : parseFloat(zoomToActiveFeatureMargin);
                    const af = results.features[0];
                    let merged = geometryEngine.union(results.features.map((f) => f.geometry));
                    let validEx = merged !== null && merged.extent !== undefined && merged.extent !== null;
                    const featureWkid = af.geometry?.spatialReference?.wkid ?? -1;
                    const viewWkid = geoView.spatialReference?.wkid ?? -1;
                    const geodesicSupported = [4326, 102113, 102100, 3857].includes(featureWkid);
                    if (viewWkid !== featureWkid) {
                        await (projection.isLoaded() ? Promise.resolve(projection) : projection.load()).then(() => {});
                        merged = projection.project(merged, geoView.spatialReference) as Geometry;
                        validEx = merged !== null && merged.extent !== undefined && merged.extent !== null;
                    }
                    let ex = validEx
                        ? merged.extent
                        : activeLayer.geometryType === 'point' && xp > 0
                        ? (geometryEngine.buffer(merged, xp, 'meters') as Polygon).extent
                        : null;
                    if (ex !== undefined && ex !== null) {
                        if (generalizeClipBoundary > 0)
                            merged = geometryEngine.generalize(merged, generalizeClipBoundary);
                        if (clipVisibleLayers) {
                            if (
                                clippers.selected !== undefined &&
                                clippers.selected !== null &&
                                clippers.selected.length > 0
                            ) {
                                for (let clipLyr of clippers.selected) {
                                    clipLyr.definitionExpression = null; // Remove non-spatial query...
                                    geoView.whenLayerView(clipLyr).then((layerView) => {
                                        reactiveUtils
                                            .whenOnce(() => layerView.updating === false)
                                            .then(() => {
                                                const ff = new FeatureFilter({
                                                    geometry: merged,
                                                    spatialRelationship: clipVisibleLayersType,
                                                    distance: parseFloat(clipVisibleLayersBuffer.toString()),
                                                    units: 'meters'
                                                });
                                                const lyrWhere = layerFilterLookup.has('*')
                                                    ? layerFilterLookup.get('*')
                                                    : layerFilterLookup.has(clipLyr.id)
                                                    ? layerFilterLookup.get(clipLyr.id)
                                                    : layerFilterLookup.has(clipLyr.title)
                                                    ? layerFilterLookup.get(clipLyr.title)
                                                    : undefined;
                                                if (lyrWhere !== undefined) {
                                                    ff.where = lyrWhere;
                                                }
                                                if (
                                                    clipVisibleLayersStyle.indexOf('fade') >= 0 &&
                                                    layerView.layer.type === 'feature'
                                                ) {
                                                    (layerView as any).featureEffect = getFeatureEffect(
                                                        ff,
                                                        clipVisibleLayersStyle,
                                                        clipHighlightLayersStyle,
                                                        drawClipBoundaryColor
                                                    );
                                                    //console.log(layerView.featureEffect); // DEBUG
                                                } else {
                                                    (layerView as any).filter = ff;
                                                    //console.log(layerView.filter); // DEBUG
                                                }
                                            });
                                    });
                                }
                            }
                            if (drawClipBoundary && drawClipBoundaryColor !== undefined) {
                                const clipPolygon = (
                                    geodesicSupported ? geometryEngine.geodesicBuffer : geometryEngine.buffer
                                )(merged, parseFloat(clipVisibleLayersBuffer.toString()), 'meters', true) as Polygon;
                                geoView.graphics.add(
                                    new Graphic({
                                        geometry: clipPolygon,
                                        symbol: new SimpleFillSymbol({
                                            // type: 'simple-fill',
                                            color: [255, 255, 255, 0],
                                            outline: {
                                                width: 1.5,
                                                color: new Color(drawClipBoundaryColor)
                                            },
                                            style: 'none'
                                        })
                                    })
                                );
                            }
                        }
                    }

                    if (xp !== 0 && ex !== null) {
                        if (activeLayer.geometryType === 'point') {
                            if (nMatches < 2) {
                                ex = new Extent({
                                    xmin: 0,
                                    ymin: 0,
                                    xmax: xp,
                                    ymax: xp,
                                    spatialReference: ex.spatialReference
                                }).centerAt(ex.center);
                            }
                        } else ex.expand(1 + xp / 100.0);
                    }
                    if (zoomToActiveFeature) {
                        await geoView
                            .goTo(ex ?? merged, {
                                animate: !prefersReducedMotion && !disableMapAnimation
                            })
                            .then(() => {
                                if (zoomToScale !== undefined && parseFloat(zoomToScale) > 0)
                                    geoView.scale = parseFloat(zoomToScale); // e.g. 1:160000
                            });
                    }
                }
                if (highlightSelectedFeature || eventsListen !== 'none') {
                    geoView.highlightOptions = {
                        color: highlightColor,
                        fillOpacity:
                            activeLayer.geometryType !== 'point' && highlightType.toLowerCase() === 'outline'
                                ? 0
                                : 0.75,
                        haloOpacity: highlightType.toLowerCase().indexOf('outline') >= 0 ? 1 : 0
                    };
                    activeLayer.visible = true;
                    // Grouped?
                    if (activeLayer.parent !== undefined && activeLayer.parent !== null) {
                        activeLayer.parent.visible = true;
                    }
                    if (highlightSelectedFeature) {
                        await activeLayer.queryFeatures(fq).then((result) => {
                            geoView['hi'] = activeLayerView.highlight(result.features);
                        });
                    }
                    if (eventsListen !== 'none') {
                        const mapIncomingEventHandler = (e) => {
                            const handler = async () => {
                                if (
                                    e !== undefined &&
                                    e.detail !== undefined &&
                                    e.detail.feature !== undefined &&
                                    e.detail.feature !== null &&
                                    e.detail.feature.id !== undefined &&
                                    e.type.indexOf('out') < 0 &&
                                    e.type.indexOf('leave') < 0
                                ) {
                                    const ew = numericId
                                            ? `${globals.idField} IN (${e.detail.feature.id}) OR ${globals.idField} IN ('${e.detail.feature.id}')`
                                            : `${globals.idField} = '${e.detail.feature.id}'`,
                                        efq = activeLayer.createQuery();
                                    efq.where = ew;
                                    const eResult = await activeLayer.queryFeatures(efq);
                                    if (geoView['hi']) geoView['hi'].remove();
                                    geoView['hi'] = activeLayerView.highlight(eResult.features);
                                } else if (geoView['hi']) {
                                    geoView['hi'].remove();
                                }
                            };
                            if (geoView['eventReactionId']) window.clearTimeout(geoView['eventReactionId']);
                            geoView['eventReactionId'] = window.setTimeout(handler, 25);
                        };
                        for (let et of eventsListen
                            .replace('hover', 'mousemove,mouseleave,mouseenter,mouseout,hover')
                            .split(/[\s,]/)) {
                            geoView.container
                                ?.closest('.ia-report-widget')
                                ?.parentNode?.addEventListener(`rb.${et}`, mapIncomingEventHandler);
                        }
                    }
                }
            }
        }
    } catch (activeLayerErr: any) {
        console.error(activeLayerErr); // DEBUG
        outputs['error'] = activeLayerErr.message !== undefined ? activeLayerErr.message : activeLayerErr.toString();
    }
    //console.log(this.props); // DEBUG
    if (!isNullOrUndefined(container)) removeClass(container, 'placeholder');
    else if (!isNullOrUndefined(document.querySelector(`.ia-report-widget[data-uuid="${id}"]`))) {
        const e: HTMLElement | undefined = document.querySelector(
            `.ia-report-widget[data-uuid="${id}"]`
        ) as HTMLElement;
        removeClass(e, 'placeholder');
    } else console.debug(`No map container found, not even .ia-report-widget[data-uuid="${id}"]`);
    //console.debug(container); // DEBUG
    return outputs;
};

const getFeatureEffect = (
    ff: FeatureFilter,
    clipVisibleLayersStyle: string,
    clipHighlightLayersStyle: string,
    drawClipBoundaryColor?: string
) => {
    let includedEffect =
        clipHighlightLayersStyle === 'halo'
            ? `drop-shadow(0px 0px 7px ${drawClipBoundaryColor || '#ffff77'})`
            : undefined;
    return {
        filter: ff,
        excludedEffect:
            clipVisibleLayersStyle === 'fade-to-gray'
                ? 'grayscale(100%) opacity(30%)'
                : clipVisibleLayersStyle === 'fade-light'
                ? 'opacity(50%)'
                : clipVisibleLayersStyle === 'fade-strong'
                ? 'opacity(10%)'
                : 'opacity(30%)',
        includedEffect
    };
};
